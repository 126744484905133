import React, {useState, useEffect, useContext} from "react";

import styled from "styled-components";
import api from "../../utils/api/api";
import appointmentService from "../../utils/api/v1/appointmentService";
import moment from "moment";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
// Contexts

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Flex from "../../components/Containers/Flex";
import Italic from "../../components/Typography/Italic";
import LoadingError from "../../components/Loaders/LoadingError";
import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// interfaces
import { TotalPackAppointment } from "../../utils/interfaces/Appointment";
import TotalPackDashboardGrid from "../../components/Appointments/TotalPackDashboardGrid";
import { isProd } from "../../utils/environment";
import { navigate } from "gatsby";
import {FiltersContext} from "../../components/Stores/FilterStore";

const Header = styled.div`
  max-width: 1400px;
  padding: 0 3rem 2rem 3rem;
  margin: 0 auto;
`;

const PrimaryButton = styled(Button)`
  max-height: 2.5rem;
  border-color: var(--primary);
  color: var(--primary);
`;

const Spaced = styled.div`
  margin: 0.5rem 1rem;
`;

moment.locale("es");

interface AppointmentRequest {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: TotalPackAppointment[];
  };
}

const IndexPage = (props): JSX.Element => {
  // @ts-ignore
  const [commonFilters, filtersDispatch] = useContext(FiltersContext);
  const [filters, setFilters] = useState({
    status: "without-connection",
    insurance: "all",
  });

  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<TotalPackAppointment[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);

  const fetchAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const request: AppointmentRequest =
        await appointmentService.fetchFingerprintAppointments(
          commonFilters.initDate.format("YYYY-MM-DD"),
          commonFilters.finishDate.format("YYYY-MM-DD"),
          filters.status,
          filters.insurance
        );
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next);
      setPrevRequest(request.data.previous);
    } catch (error) {
      setError(error);
      console.error(error);
    }
    setLoading(false);
  };

  const connectWithTotalPack = async () => {
    setLoading(true);
    try {
      const allValuationResult = await Promise.allSettled(
        appointments
          .filter(
            (appointment) =>
              !["connected-successfully", "closed-appointment"].includes(
                appointment.totalpack_request_status
              )
          )
          .map(async (appointment) => {
            const valuationResult = await appointmentService.totalpackValuation(
              appointment.id
            );
            return valuationResult;
          })
      );
      const allValuationsFulfilled = allValuationResult.every(
        (result) => result.status === "fulfilled"
      );
      const allValuationsRejected = allValuationResult.every(
        (result) => result.status === "rejected"
      );
      const someValuationsRejected = allValuationResult.some(
        (result) => result.status === "rejected"
      );

      const fulfilledData = allValuationResult
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value.data.data);

      const allConfirmationResult = await Promise.allSettled(
        fulfilledData.map(async (data) => {
          const confirmationResult =
            await appointmentService.totalpackPaymentConfirm(
              data.appointment_id,
              data.prebono_ids
            );
          return confirmationResult;
        })
      );

      const allConfirmationFulfilled = allConfirmationResult.every(
        (result) => result.status === "fulfilled"
      );
      const allConfirmationRejected = allConfirmationResult.every(
        (result) => result.status === "rejected"
      );
      const someConfirmationRejected = allConfirmationResult.some(
        (result) =>
          result.status === "rejected" ||
          result?.data?.data?.partially_connected
      );

      if (allValuationsFulfilled && allConfirmationFulfilled) {
        await Swal.fire({
          title: "¡Listo!",
          text: "Todas las citas han sido conectadas con éxito.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else if (someConfirmationRejected || someValuationsRejected) {
        await Swal.fire({
          title: "¡Error!",
          text: "Algunas citas no se pudieron conectar correctamente. Revisar manualmente.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (allValuationsRejected || allConfirmationRejected) {
        await Swal.fire({
          title: "¡Error!",
          text: "Ninguna cita se pudo conectar correctamente. Revisar manualmente.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      setError(error);
      console.error(error);
    }
    setLoading(false);
  };

  const handleGenerateTotalPack = async () => {
    let response = await callGenerateAppointmentsTotalPack();
    navigate(`/dashboard/${response.id_task}/totalpack-masive-downloader/`);
  };
  const callGenerateAppointmentsTotalPack = async () => {
    setLoading(true);
    let request = undefined;
    try {
      request = await api.post("/v2/totalpack-download", {
        begin_date: commonFilters.initDate.format("YYYY-MM-DD"),
        end_date: commonFilters.finishDate.format("YYYY-MM-DD"),
      });
    } catch (err) {
      setError(err);
    }
    setLoading(false);
    return request?.data;
  };

  const handleTotalPackConnect = async (): Promise<void> => {
    const someConnected = appointments.some(
      (appointment) =>
        appointment.totalpack_request_status === "connected-successfully"
    );
    const allConnected = appointments.every(
      (appointment) =>
        appointment.totalpack_request_status === "connected-successfully"
    );

    if (allConnected) {
      await Swal.fire({
        title: "Error",
        text: "Todas las citas ya han sido conectadas.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    } else if (someConnected) {
      const result = await Swal.fire({
        title: "Confirmación de conexión",
        text: "¿Está seguro de que desea conectar todas las citas? Solo se conectarán aquellas que no hayan sido conectados previamente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, conectar todos",
        cancelButtonText: "Cancelar",
      });
      if (result.value) {
        await connectWithTotalPack();
        await fetchAppointments();
      }
    } else {
      const result = await Swal.fire({
        title: "Confirmación de conexión",
        text: `¿Está seguro de que desea conectar las ${appointments.length} citas?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, conectar todos",
        cancelButtonText: "Cancelar",
      });
      if (result.value) {
        await connectWithTotalPack();
        await fetchAppointments();
      }
    }
  };

  const handleFilterButton = (): void => {
    fetchAppointments();
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    let nextUrl = action === "next" ? nextRequest : prevRequest;
    if (!nextUrl) {
      return;
    }
    if (isProd && nextUrl.includes("http://")) {
      nextUrl = nextUrl.replace("http://", "https://");
    }
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: nextUrl,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  useEffect((): void => {
    fetchAppointments();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError error={error} loading={loading} />
      {!loading && isEmpty(error) && (
        <>
          <Header>
            <Flex direction="column" align="center">
              <h1>
                <Italic>Appointments Total Pack</Italic>
              </h1>
              <Flex align="center" justify="center" wrap="wrap">
                <Spaced>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha inicio"
                      value={commonFilters?.initDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_INITDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Spaced>
                <Spaced>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha término"
                      value={commonFilters?.finishDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_FINISHDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Spaced>
              </Flex>
              <Spaced>
                <Flex direction="row" justify="space-between">
                  <FormControl>
                    <Select
                      value={filters?.status}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          status: e.target.value as string,
                        })
                      }
                    >
                      <MenuItem value="without-connection">
                        Sin conexión
                      </MenuItem>
                      <MenuItem value="all">Todos</MenuItem>
                      <MenuItem value="connected-successfully">
                        Conectado correctamente
                      </MenuItem>
                      <MenuItem value="partially-connected">
                        Conexión parcial
                      </MenuItem>
                      <MenuItem value="closed-appointment">
                        Cita cerrada
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      value={filters?.insurance}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          insurance: e.target.value as string,
                        })
                      }
                    >
                      <MenuItem value="cruzblanca">CruzBlanca</MenuItem>
                      <MenuItem value="nueva_masvida">Nueva Más Vida</MenuItem>
                      <MenuItem value="all">Todos</MenuItem>
                      <MenuItem value="fonasa">Fonasa</MenuItem>
                    </Select>
                  </FormControl>
                </Flex>
              </Spaced>
              <Flex align="center" justify="center" wrap="wrap">
                <Spaced>
                  <PrimaryButton
                    variant="outlined"
                    color="primary"
                    onClick={handleFilterButton}
                  >
                    Filtrar
                  </PrimaryButton>
                </Spaced>
                <Spaced>
                  <Button
                    variant="outlined"
                    style={{ color: "green" }}
                    onClick={handleTotalPackConnect}
                  >
                    Conectar con TotalPack
                  </Button>
                </Spaced>
                <Spaced>
                  <Button
                    variant="outlined"
                    style={{ color: "green" }}
                    onClick={handleGenerateTotalPack}
                  >
                    Descargar zip de archivos
                  </Button>
                </Spaced>
              </Flex>
            </Flex>
          </Header>
          <TotalPackDashboardGrid
            appointments={appointments}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
